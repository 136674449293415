<template>
  <section class="select-section-area">
    <div class="select-section-area-head d-flex align-items-center">
      <h2>{{ cabinType }}</h2>
      <h2>{{$t("sabre.booking.seat-box.seat-selection")}} - {{ curPassengerName }}</h2>
      <div class="d-flex align-items-center justify-content-between">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item">{{ cityCode.departure }} {{ departureCityName }}</li>
            <li class="breadcrumb-item">{{ cityCode.arrival }} {{ arrivalCityName }}</li>
          </ol>
        </nav>
        <h3>
          <tooltip-item :ticketModal="true"/>
           מקרא מושבים
        </h3>
      </div>
    </div>

    <div class="container">
      <div class="row">
        <div class="col-lg-9 order-1" v-if="isLoading">
          <content-loading type='banner'/>
        </div>
        <div class="col-lg-9 order-1" v-else-if="matchedSeatData && matchedSeatData.simplifiedSeatMap">
          <div class="SelectBox card" :class="`seat-count-${columnList.length}`">
            <div class="card-header">
              <ul class="d-flex justify-content-between flex-row">
                <li v-for="(col, inx) in columnList" :key="inx">
                  <div class="Headingtext d-flex justify-content-center align-items-center"> {{col.column}} </div>
                </li>
              </ul>
            </div>
            <div class="card-body">
              <ul class="d-flex justify-content-between flex-wrap" v-for="(row, rowIndex) in rowList" :key="rowIndex">
                <!-- <div class="d-flex justify-content-between flex-wrap w-100" v-if="!row.seat">
                  <li v-for="(col, colInx) in columnList" :key="colInx">
                    <label class="SelectSit Number">
                      <input type="checkbox" value="" />
                      <span class="SelectSitTextBox d-flex align-items-center justify-content-center"></span>
                    </label>
                  </li>
                </div> -->

                <li v-for="(seat, seatIndex) in row.seat" :key="seatIndex">
                  <one-seat-map-info
                    :person="curPerson" :row="row" :seat="seat" :roadIndexes="roadIndexes" :seatData="currentFlightSeatData" :seatIndex="seatIndex"
                    @setSeat="selectSeat" @unsetSeat="unselectSeat" :wingData="wing"/>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="col-lg-9 order-1" v-else>
          <p> No seat data !</p>
        </div>
        <div class="col-lg-3 order-2">
          <div class="right-section">
            <div class="RightListBox card">
              <div class="card-header">
                <h4>מקרא מושבים</h4>
              </div>
              <div class="card-body">
                <ul class="list-group list-group-flush">
                  <li class="list-group-item"> {{$t("sabre.booking.seat-box.selected-seat")}} <div class="box color1"></div>
                  </li>
                  <li class="list-group-item"> {{$t("sabre.booking.seat-box.standard-seat")}} <div class="box color2"></div>
                  </li>
                  <li class="list-group-item"> {{$t("sabre.booking.seat-box.exit-line")}} <div class="box color3"></div>
                  </li>
                  <li class="list-group-item"> {{$t('sabre.booking.seat-box.busy')}} <div class="box color4"></div>
                  </li>
                </ul>
              </div>
            </div>
            <div class="RightListBox RightListBoxCheck card">
              <div class="card-header">
                <h4>{{$t("sabre.booking.seat-box.traveling")}}</h4>
              </div>
              <div class="card-body">
                <ul class="list-group list-group-flush">
                  <li class="list-group-item" v-for="(passenger, inx) in passengerInfo" :key="inx">
                    <one-person-ticket-info :person="passenger" :seatData="currentFlightSeatData"/>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="select-section-area-footer d-flex align-items-center justify-content-end">
      <h4>{{$t("sabre.booking.seat-box.overall-supplement")}}<strong>${{totalPrice}} </strong>
      </h4>
      <a href="#" class="btn btn-warning" @click="update">{{$t("sabre.buttons.order")}}</a>
    </div>
  </section>
</template>

<script>

import { mapGetters } from 'vuex';

export default {
  name: 'ticket-selection-modal',
  props: {
    segmentData: {
      type: Object,
      default: null,
    },
  },
  components: {
    TooltipItem: () => import('@/sabre/common/atom/tooltipItem'),
    ContentLoading: () => import('@/components/atoms/ContentLoading'),
    OnePersonTicketInfo: () => import('./onePersonTicketInfo'),
    OneSeatMapInfo: () => import('./oneSeatMapInfo'),
  },
  computed: {
    ...mapGetters({
      isLoading: 'GET_LOADING_STATE',
      originalSeatData: 'GET_SABRE_FO_BOOKING_SEATS_ORIGINAL_DATA',
      orderedFlight: 'GET_SABRE_ORDERED_FLIGHT',
      destinationList: 'GET_DESTINATION_IMAGES',
      selectedSeatData: 'GET_SABRE_FO_BOOKING_SELECTED_SEAT_DATA',
      passengerInfo: 'GET_SABRE_PASSENGER_DATA',
      cabinInfo: 'GET_SABRE_FO_BOOKING_SEATS_CABIN_INFO',
    }),
    curPassengerName() {
      const { passengerInfo, segmentData } = this;
      const passenger = passengerInfo[segmentData.passengerIndex];
      return `${passenger.firstName} ${passenger.lastName}`;
    },
    curPerson() {
      const { passengerInfo, segmentData } = this;
      return passengerInfo[segmentData.passengerIndex];
    },
    matchedSeatData() {
      const { segmentData, originalSeatData } = this;
      const { flightIndex, segmentIndex, passengerIndex } = segmentData;
      const seatDataKey = `${passengerIndex}-${flightIndex}-${segmentIndex}`;

      if (!originalSeatData) return null;
      const data = originalSeatData.find((seat) => seat.seatDataKey === seatDataKey) || null;
      return data || null;
    },
    cabinPrice() {
      const { matchedSeatData } = this;
      if (!matchedSeatData) return 0;
      if (!matchedSeatData.simplifiedSeatMap) return 0;
      return matchedSeatData.simplifiedSeatMap.cabin.reduce((total, cab) => total + +cab.priceDefault, 0);
    },
    columnList() {
      const { matchedSeatData } = this;
      if (!matchedSeatData) return [];
      if (!matchedSeatData.simplifiedSeatMap) return [];
      const originCol = matchedSeatData.simplifiedSeatMap.cabin[0].column;
      const returnCol = [];
      const emptyElement = { column: '' };

      originCol.forEach((c, inx) => {
        returnCol.push(c);
        if (c.characteristics?.includes('Aisle') && !originCol[inx - 1].characteristics?.includes('Aisle')) {
          returnCol.push(emptyElement);
        }
      });
      returnCol.forEach((c, inx) => {
        if (c.column === '') this.addRoadIndex(inx);
      });

      return returnCol;
    },
    rowList() {
      const { matchedSeatData, columnList } = this;
      if (!matchedSeatData) return [];
      if (!matchedSeatData.simplifiedSeatMap) return [];
      const row = matchedSeatData.simplifiedSeatMap.cabin.reduce((total, cab) => ([...total, ...cab.row]), []);

      // add number col
      row.forEach((r) => this.roadIndexes.forEach((road) => r.seat && r.seat[road].number && r.seat.splice(road, 0, {})));

      // add exit row
      const exitRowNumbers = [];
      row.forEach((r, inx) => {
        if (r.seat && this.findExitRow(r) && !exitRowNumbers.includes(inx - 1)) {
          exitRowNumbers.push(inx);
        }
      });
      exitRowNumbers.forEach((exit, inx) => {
        row.splice(exit + inx + 1, 0, { rowNumber: '', seat: columnList.map(() => ({ empty: true })) });
      });

      return row;
    },
    destinations() {
      const { destinationList } = this;
      // return destinationList?.filter((dest) => dest.forSabreFlight) || [];
      return destinationList?.filter((dest) => dest.name) || [];
    },
    cityCode() {
      const { segmentData, orderedFlight } = this;
      const { flightIndex, segmentIndex } = segmentData;
      const segment = orderedFlight?.legs[flightIndex]?.segmentExcerpts[segmentIndex] || null;

      return { departure: segment?.departure?.airport || '', arrival: segment?.arrival?.airport || '' };
    },
    departureCityName() {
      const { destinations, cityCode } = this;
      return destinations.find((d) => d.code === cityCode.departure)?.name_Loc?.content || '';
    },
    arrivalCityName() {
      const { destinations, cityCode } = this;
      return destinations.find((d) => d.code === cityCode.arrival)?.name_Loc?.content || '';
    },
    currentFlightSeatData() {
      const { segmentData, selectedSeatData } = this;
      const { flightIndex, segmentIndex } = segmentData;

      return selectedSeatData?.filter((seat) => seat.flightIndex === flightIndex && seat.segmentIndex === segmentIndex) || [];
    },
    wing() {
      const { matchedSeatData } = this;
      const wing = matchedSeatData?.simplifiedSeatMap?.cabin.reduce((total, curr) => ({
        firstRow: (+total.firstRow < (+curr.wing?.firstRow || Infinity)) ? total.firstRow : curr.wing?.firstRow,
        lastRow: (+total.lastRow > (+curr.wing?.lastRow || 0)) ? total.lastRow : curr.wing?.lastRow,
      }), { firstRow: Infinity, lastRow: 0 });

      return (wing.firstRow && wing.lastRow) ? wing : null;
    },
    cabinType() {
      const { cabinInfo, matchedSeatData } = this;
      const { cabinType } = matchedSeatData?.simplifiedSeatMap?.cabin[0] || {};
      return cabinType || cabinInfo?.cabinCode || '';
    },
  },
  data: () => ({
    roadIndexes: [],
    totalPrice: 0,
  }),
  mounted() {
    this.calcTotalPrice();
  },
  methods: {
    findExitRow(row) {
      if (!row || !row.seat) return false;
      return (row.type && row.type[0] === 'ExitRow') || row.seat.some((s) => s.exitRow);
    },
    addRoadIndex(inx) {
      if (this.roadIndexes.includes(inx)) return;
      this.roadIndexes.push(inx);
    },
    selectSeat(payload) {
      const { seat, rowNumber, row } = payload;
      const { segmentData, cabinPrice } = this;
      const { passengerIndex, flightIndex, segmentIndex } = segmentData;
      const currentPassenger = this.passengerInfo[segmentData.passengerIndex];

      if (seat.noInfant && currentPassenger.accompaniedByInfant) {
        this.$store.commit('SET_SABRE_ERROR_ALERT_MESSAGE', 'You can\'t select this seat because you have an infant.');
        return;
      }

      const price = seat.chargeable ? ((seat.offer && seat.offer[0]?.price?.value) ?? row.price ?? cabinPrice ?? 0) : 0;

      this.$store.commit('SET_SABRE_FO_BOOKING_SELECTED_SEAT_DATA', {
        flightIndex,
        segmentIndex,
        passengerIndex,
        seatNumber: `${seat.number}${rowNumber}`,
        currencyCode: (seat.offer && seat?.offer[0]?.price?.currencyCode) || '',
        price,
        passengerType: currentPassenger.passengerType,
        passengerTypeIndex: currentPassenger.typeIndex,
      });
      this.$forceUpdate();
      this.calcTotalPrice();
      this.$emit('update', false);
    },
    unselectSeat(oldData) {
      const { segmentData } = this;
      const { flightIndex, segmentIndex } = segmentData;

      this.$store.commit('SET_SABRE_FO_BOOKING_SELECTED_SEAT_DATA', {
        remove: true,
        flightIndex,
        segmentIndex,
        passengerIndex: oldData.passengerIndex,
      });
      this.$forceUpdate();
      this.calcTotalPrice();
      this.$emit('update', false);
    },
    calcTotalPrice() {
      const { currentFlightSeatData } = this;
      this.totalPrice = currentFlightSeatData?.reduce((total, current) => total + (current?.price || 0), 0) || 0;
    },
    update() {
      this.$emit('update', true);
    },
  },
};
</script>

<style>
  .SelectBox.card .card-body {
    direction: ltr;
  }
  .SelectBox.card.seat-count-11 {
    max-width: 530px;
  }
  .SelectBox.card.seat-count-11 ul li {
    max-width: 9%;
  }
  .SelectBox.card.seat-count-12 {
    max-width: 590px;
  }
  .SelectBox.card.seat-count-12 ul li {
    max-width: 8%;
  }
  .SelectBox.card.seat-count-7 {
    max-width: 350px;
  }
  .SelectBox.card.seat-count-7 ul li {
    max-width: 14%;
  }
  .SelectBox.card.seat-count-5 {
    max-width: 250px;
  }
  .SelectBox.card.seat-count-5 ul li {
    max-width: 20%;
  }
</style>
